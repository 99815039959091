(function(w, d) {
    var schedule_notice = {
        init: function () {
            w.util_schedule_config.forEach(function(o) {
                fetch(o.feed)
                .then(function(response) { return response.text(); })
                .then(function(str) { return new w.DOMParser().parseFromString(str, 'text/xml'); })
                .then(function(nodes) { return nodes.documentElement.childNodes; })
                .then(function(data) {
                    let programs = [],
                        live = false,
                        sHtml = '',
                        curDate = (new Date).getTime(),
                        curDay = new Date().getDay(),
                        weekdays = new Array(7);
                            weekdays[0] = 'Sun';
                            weekdays[1] = 'Mon';
                            weekdays[2] = 'Tue';
                            weekdays[3] = 'Wed';
                            weekdays[4] = 'Thu';
                            weekdays[5] = 'Fri';
                            weekdays[6] = 'Sat';
                    
                    // Create program array
                    for (let i = 0; i < data.length ; i++) {
                        if (data[i].nodeName == 'schedule'){
                            programs = data[i].childNodes;
                            break;
                        }
                    }
                    
                    // Iterate through program schedule
                    for (let i = 0; i < programs.length ; i++) {
                        var rowClass = (i % 2 == 0) ? 'even' : 'odd',
                            programData = programs[i].childNodes,
                            dataString,
                            title,
                            startUTC,
                            startDay,
                            startDayString = '',
                            endUTC;
                        
                        // Iterate through current program data
                        for (let j = 0; j < programData.length ; j++) {
                            // Get individual program data
                            dataString = programData[j].textContent;
                            
                            // Program title
                            if (programData[j].nodeName == 'title') {
                                title = dataString;
                            }
                            
                            // Program start time
                            if (programData[j].nodeName == 'begin') {
                                // Create usable date string (with special consideration for IE11)
                                dataString = dataString.replace(' ', 'T') + ':00.000Z';
                                
                                // Get usable date object
                                startUTC = new Date(dataString);
                                
                                // Start day comparison
                                startDay = startUTC.getDay();
                                if (startDay != curDay) {
                                    startDayString = weekdays[startDay] + ' ';
                                }
                            }
                            
                            // Program end time
                            if (programData[j].nodeName == 'end') {
                                // Create usable date string (with special consideration for IE11)
                                dataString = dataString.replace(' ', 'T') + ':00.000Z';
                                
                                // Get usable date object
                                endUTC = new Date(dataString);
                                
                                // Program pieces are all in place, add to display
                                sHtml += '<div class="time '+ rowClass +'">' + startDayString + schedule_notice.formatTime(startUTC) + '</div><div class="program '+ rowClass +'">'+ title +'</div>';
                                
                                // Check if current instance is live and update widget
                                if ( (live != true) && (startUTC.getTime('u') < curDate) && (curDate < endUTC.getTime('u')) ) {
                                    live = true;
                                    d.querySelector('#block-'+ o.id + ' .description').innerHTML = o.live_description;
                                    d.querySelector('#block-'+ o.id + ' .btn').classList.add('live');
                                }
                            }
                        }
                    }
                    
                    // Update widget
                    if (live != true) {
                        d.querySelector('#block-'+ o.id + ' .description').innerHTML = o.description;
                    }
                    
                    // Insert schedule
                    d.getElementById('schedule-'+ o.id).innerHTML = sHtml;
                });
            });
        },
        // Format time for display
        formatTime: function (date) {
            let hours = date.getHours(),
                minutes = date.getMinutes(),
                ampm = hours >= 12 ? 'PM' : 'AM';
            hours = hours % 12;
            hours = hours ? hours : 12; // the '0' hour is '12'
            hours = hours < 10 ? '0' + hours : hours; // pad hours for display
            minutes = minutes < 10 ? '0' + minutes : minutes;
            let strTime = hours + ':' + minutes + ' ' + ampm;
            return strTime;
        }
    };
    if (d.readyState == 'loading') {
        d.addEventListener('DOMContentLoaded', function() {
            schedule_notice.init();
        });
    } else {
        schedule_notice.init();
    }
})(window, document);